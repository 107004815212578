/* eslint-disable react-hooks/exhaustive-deps */
import queryString from "query-string";
import TankNode from "./components/TankNode";
import ReactFlow, { applyEdgeChanges, applyNodeChanges } from "react-flow-renderer";
import { useCallback, useEffect, useState } from "react";
import { initialNodes, initialEdges, defaultProductionData, ITankData, IPumpData } from "./flowData";
import PumpNode from "./components/PumpNode";

import ProductionStatus from "./components/ProductionStatus";
import useSubscription from "./@mqtt-react-hooks/useSubscription";
import { IProductionData } from "./flowData";
import useMqttState from "./@mqtt-react-hooks/useMqttState";
import "./App.css";

const nodeTypes = { Tank: TankNode, Pump: PumpNode };
//const deviceMac = "b8:27:eb:b3:c0:26";
enum MQTT_TOPICS {
  "PRODUCTION_STATUS" = "PRODUCTION_STATUS",
  "ALARMS" = "ALARMS",
  "PROD_CTRL" = "PRODUCTION",
  "PUMP_CTRL" = "CONTROL",
  "WATER_PUMP_CTRL" = "WATER_PUMP_CONTROL",
  "CLEAR_ALARM" = "CLEAR_ALARM",
}
function App() {
  const { client } = useMqttState();
  const [productionData, setProductionData] = useState(defaultProductionData);
  const [deviceMac, setDeviceMac] = useState("");

  const { message: payload } = useSubscription([
    `${MQTT_TOPICS.PRODUCTION_STATUS}/${deviceMac}`,
    `${MQTT_TOPICS.ALARMS}/${deviceMac}`,
  ]);

  useEffect(() => {
    // console.log({ payload });
    onPayloadRecieved(payload?.topic || "", payload?.message || {});
  }, [payload]);

  useEffect(() => {
    // processData(productionData);
    const qs = queryString.parse(window.location.search);
    if (qs.d) {
      const device = qs.d.toString();
      setDeviceMac(device);
    }
  }, []);

  const onPayloadRecieved = (topic: string, message: any) => {
    if (!topic || !message) return;

    if (topic === `${MQTT_TOPICS.PRODUCTION_STATUS}/${deviceMac}`) {
      console.log("msg rcvd", message);
      setProductionData((prevState) => ({
        ...prevState,
        ...message,
        water_tank: message.water_bund === "high_alarm" ? "high_alarm" : message.water_tank,
        product_tank: message.prod_bund === "high_alarm" ? "high_alarm" : message.product_tank,
      }));
    }
  };

  const sendStartStopMessage = (start_stop: "start" | "stop") => {
    const payload = { action: start_stop };
    client?.publish(`${MQTT_TOPICS.PROD_CTRL}/${deviceMac}`, JSON.stringify(payload));
  };

  return (
    <div className="w-full bg-white p-2  flex flex-row">
      {deviceMac === "" ? (
        <div className="h-screen flex text-5xl items-center justify-center w-full">No Device specified</div>
      ) : (
        <>
          <ProductionStatus
            productionData={productionData}
            setProductionData={setProductionData}
            startStop={sendStartStopMessage}
            deviceId={deviceMac}
          />
        </>
      )}
    </div>
  );
}

export default App;



  // const processData = (prodData: IProductionData) => {
  //   const tanks = updateTanks(prodData);
  //   const pumps = updatePumps(prodData);

  //   setNodes([...tanks, ...pumps]);
  //   toggleEdgesAnimate(prodData.production_status !== "started");
  // };
//const onNodesChange = useCallback((changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)), [setNodes]);
  //const onEdgesChange = useCallback((changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);

  // const updateTanks = (prodData: IProductionData) => {
  //   const waterTank: ITankData = {
  //     type: "wt",
  //     status: prodData.water_bund === "high_alarm" ? prodData.water_bund : prodData.water_tank,
  //   };
  //   const acidTank: ITankData = { type: "at", status: prodData.acid_tank };
  //   const viberexTank: ITankData = { type: "vt", status: prodData.viberex_tank };
  //   const prodTank: ITankData = {
  //     type: "pt",
  //     status: prodData.prod_bund === "high_alarm" ? prodData.prod_bund : prodData.product_tank,
  //   };

  //   const _tanksData: ITankData[] = [waterTank, acidTank, viberexTank, prodTank];

  //   const _tankIds = _tanksData.map((t) => t.type);

  //   const tankNodes = nodes.filter((n, i) => _tankIds.includes(n.id as ITankData["type"]));

  //   const updatedTankNodes = tankNodes.map((tn) => ({
  //     ...tn,
  //     data: { ...tn.data, status: _tanksData.find((t) => t.type === tn.id)?.status },
  //   }));

  //   return updatedTankNodes;
  // };

  // const updatePumps = (prodData: IProductionData) => {
  //   const waterPump: IPumpData = { type: "wp", status: prodData.water_pump };
  //   const acidPump: IPumpData = { type: "ap", status: prodData.acid_pump };
  //   const viberexPump: IPumpData = { type: "vp", status: prodData.viberex_pump };

  //   const _pumpData: IPumpData[] = [waterPump, acidPump, viberexPump];
  //   console.table(_pumpData);
  //   console.log(prodData);
  //   const _pumpIds = _pumpData.map((t) => t.type);

  //   const pumpNodes = nodes.filter((n) => _pumpIds.includes(n.id as IPumpData["type"]));

  //   const updatedPumpNodes = pumpNodes.map((tn) => ({
  //     ...tn,
  //     data: { ...tn.data, status: _pumpData.find((t) => t.type === tn.id)?.status },
  //   }));

  //   return updatedPumpNodes;
  // };

  // const toggleEdgesAnimate = (state: boolean) => {
  //   const es = edges.map((e) => ({ ...e, hidden: e.id.indexOf("-flow") > -1 ? state : false }));
  //   setEdges(es);
  // };

/*
    <div className="w-3/4 h-[calc(100vh-100px)]">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              className="border-2"
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              // nodesDraggable={true}
              // zoomOnScroll={false}
              // zoomOnPinch={false}
              // zoomOnDoubleClick={false}
              fitView
            />
          </div> */
import { Card, CardBody } from "./Card";

const WidgetStatCard = ({
  reverse,
  title,
  subtitle,
  value,
  icon,
  fill,
  bgClassName,
  btnClassName,
  btnTextClassName,
  subtitle2 = null,
  textClassName = "text-white",
}: WidgetStatCardProps) => {
  let before = null,
    after = null;

  const cardIcon = (
    <>
      {icon && (
        <button
          className={`${
            fill ? "bg-white bg-opacity-20" : btnClassName
          } ${btnTextClassName} relative rounded-full flex justify-center w-12 h-12  items-center  focus:outline-none`}
        >
          {icon}
        </button>
      )}
    </>
  );

  if (icon) {
    reverse ? (before = cardIcon) : (after = cardIcon);
  }

  return (
    <Card className={`widget-card ${bgClassName}`}>
      <CardBody>
        <div className="flex-auto">
          <div className="flex flex-wrap items-center">
            {before}
            {reverse && <span className="flex-auto" />}
            <div className={`relative ${reverse ? "text-right" : ""}`}>
              <h5 className={`uppercase font-semibold text-md ${textClassName}`}>{title}</h5>
              {value && <span className={`mb-0 font-semibold text-md text-lg ${textClassName}`}>{value}</span>}
            </div>
            {!reverse && <span className="flex-auto" />}
            {after}
          </div>
          {subtitle && (
            <div className={`text-xs mt-2 ${reverse ? "text-right" : ""} ${fill ? "text-gray-200" : "text-gray-500"}`}>
              {subtitle}
            </div>
          )}
          {subtitle2}
        </div>
      </CardBody>
    </Card>
  );
};

interface WidgetStatCardProps {
  reverse: boolean;
  fill: boolean;
  title: string;
  subtitle?: React.ReactNode;
  subtitle2?: React.ReactNode;
  value: string;
  icon: any;
  bgClassName: string;
  btnClassName: string;
  btnTextClassName: string;
  textClassName?: string;
}

export default WidgetStatCard;

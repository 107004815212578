import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { Connector } from "./@mqtt-react-hooks/";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
// processPumps
// 4ee3xBjJ6mfZ1vskFkAVjr0QSsE9Dcvn
root.render(
  <>
    <Connector
      brokerUrl={"ws://143.198.137.203:9001"}
      options={{
        keepalive: 10,
        username: "processPumps",
        password: "4ee3xBjJ6mfZ1vskFkAVjr0QSsE9Dcvn",
        clientId: "process-pumps-control-ui-" + Math.random().toString(16).substring(2, 8),
      }}
      parserMethod={(msg) => JSON.parse(msg) || "error parsing"}
    >
      <App />
    </Connector>
  </>
);

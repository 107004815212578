import WidgetStatCard from "./shared/WidgetStatusCard";

interface Props {
  attribute: string;
  value: string;
  deviceId: string;
  cardClassName: string;
  textClassName: string;
}

const TankCard = ({ attribute, value, cardClassName, textClassName }: Props) => {
  // const { client } = useMqttState();

  // const tankAction = (action: "start" | "stop") =>
  //   client?.publish(`CONTROL/${deviceId}`, JSON.stringify({ action, tank }));
  const getClassNames = (value: string) => {
    if (value === "high_alarm" || value === "empty") return "text-red-600 bg-red-100";
    if (value === "low") return "text-yellow-600 bg-yellow-100";
    return "text-green-600 bg-green-100";
  };

  return (
    <>
      <WidgetStatCard
        title={attribute}
        value={""}
        subtitle2={
          <div className={`text-base mt-2 w-full p-5 text-center ${getClassNames(value)} `}>
            {value.toUpperCase() ?? "-"}
          </div>
        }
        icon={null}
        fill={true}
        reverse={false}
        bgClassName={cardClassName}
        textClassName={textClassName}
        btnClassName={""}
        btnTextClassName={""}
      />
    </>
  );
};

export default TankCard;

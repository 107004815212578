import { useContext, useEffect, useCallback, useState } from "react";

import { IClientSubscribeOptions } from "mqtt";
import mqttPattern from "mqtt-pattern";

import MqttContext from "./Context";
import { IMqttContext as Context, IUseSubscription, IMessage } from "./types";

export default function useSubscription(
  topic: string | string[],
  options: IClientSubscribeOptions = {} as IClientSubscribeOptions
): IUseSubscription {
  const { client, connectionStatus, parserMethod } = useContext<Context>(MqttContext);

  const [message, setMessage] = useState<IMessage | undefined>(undefined);

  const subscribe = useCallback(async () => {
    client?.subscribe(topic, options);
  }, [client, options, topic]);

  const callback = useCallback(
    (receivedTopic: string, receivedMessage: any) => {
      if ([topic].flat().some((rTopic) => mqttPattern.matches(rTopic, receivedTopic))) {
        try {
          setMessage({
            topic: receivedTopic,
            message: parserMethod?.(receivedMessage) || receivedMessage.toString(),
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [parserMethod, topic]
  );

  useEffect(() => {
    if (client?.connected) {
      subscribe();

      client.on("message", callback);
    }
    return () => {
      client?.off("message", callback);
    };
  }, [callback, client, subscribe]);

  return {
    client,
    topic,
    message,
    connectionStatus,
  };
}

interface Props {
  attribute: string;
  value: string;
  className?: string;
}
const StatusAttribute = ({ attribute, value, className = "text-xl" }: Props) => (
  <div className={`${className} flex w-full  `}>
    <div className="w-1/2 p-2">{attribute}:</div>
    <div className="w-1/2 p-2 uppercase">{value}</div>
  </div>
);

export default StatusAttribute;

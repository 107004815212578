/* eslint-disable */

const PumpSVGOff = () => {
  return (
    <svg
      id="ev2IhL30fSH1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1000 1000"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g>
        <g>
          <path d="M215.6,564.3c-1.9-9.5-3.8-18.1-5.2-26.7-5.5-34.2-2.6-68,4.7-101.8c9.7-44.9,29-85,58.3-120c40.9-49,92.1-81.9,154.9-96.1c25.8-5.8,51.6-9,78-8.9c96.4.3,192.7-.4,289.1.4c34.5.3,62.1,15.5,79.4,46.5c7.2,12.9,10,27.3,10.8,41.9.9,16.2,1,32.4,0,48.6-2,34.7-16.7,62.3-48.3,78.8-12.7,6.7-26.8,9.1-41.1,10-6.6.5-13.2.1-19.9.1c1.8,10.4,3.9,20.4,5.2,30.5c4.3,32,1,63.4-5.8,94.9-9.7,44.9-29.1,84.9-58.4,120-40.9,48.9-91.8,82.1-154.6,95.7-17.2,3.7-34.9,5.4-52.1,9.2-13.6,3-27.2,2.9-40.9,2.9-88.1,0-176.2.3-264.3-.2-23-.1-44.5-6.7-61.8-22.9-11.9-11.2-21.2-24.1-25.1-40.6-3.2-13.3-5.1-26.5-4.6-40.2.5-14-.4-28.2,1.2-42c2.1-17.9,7.6-34.8,20.2-48.6c11.3-12.4,24.1-22.5,40.8-26.6c13-3.3,25.6-6.7,39.5-4.9ZM744,498.8c-.1-137.2-111.5-248.4-248.7-248.2-137.2.1-248.3,111.5-248.2,248.6.1,137.2,111.5,248.4,248.7,248.2C633,747.3,744.1,636,744,498.8ZM643.2,250.5c27,18.4,52.5,37.8,72.9,63.3c20.3,25.4,37.9,52.4,48.8,83.5c10.4,0,20.7,0,30.9,0c28.5-.1,50.2-21.7,50.3-50.2.1-14.9,0-29.7,0-44.6c0-30.8-21.2-52-52-52-48.7,0-97.5,0-146.2,0-1.5,0-3.1,0-4.7,0ZM226.9,603.7c-7.6,0-15.2,0-22.8,0-28.5.1-50.2,21.8-50.3,50.2-.1,15,0,30.1,0,45.1c0,30.1,21.4,51.4,51.6,51.4c47.6,0,95.2,0,142.8,0c1.6,0,3.1,0,4.7,0-58.4-34.9-100.2-83.8-126-146.7ZM931.5,321.5c0-27.6-.1-55.2,0-82.8.1-14.3,10.6-26.1,24.9-28.5c13.6-2.3,27.4,6.1,31.9,19.5c1.1,3.2,1.6,6.8,1.6,10.2.1,54.4.1,108.8,0,163.1c0,16.9-12.8,29.9-29.1,30-16.5.1-29.3-13.1-29.4-30.2c0-27.1.1-54.2.1-81.3ZM10,677.5c0-27.6-.1-55.2,0-82.8.1-14.3,10.6-26.1,24.9-28.5c13.6-2.3,27.4,6.1,31.9,19.5c1.1,3.2,1.6,6.8,1.6,10.2.1,54.4.1,108.8,0,163.1c0,16.9-12.8,29.9-29.1,30-16.5.1-29.3-13.1-29.4-30.2.1-27,.1-54.1.1-81.3Z" />
        </g>
      </g>
      <g id="ev2IhL30fSH5" transform="translate(1.499756 0)">
        <path d="M618.7,365.2l-67.1,68.7c-11.9-9.9-26.6-16.4-42.7-18.5v-101.4C392.6,314,365,378.8,365,378.8l67.6,66C422,457.3,415,472.9,413.2,490h-99.5c0,116.3,64.8,143.9,64.8,143.9L444.7,566c12.6,10,28.1,16.4,45,18v99.7c116.3,0,143.9-64.8,143.9-64.8l-69.1-67.4c9.3-12,15.6-26.5,17.3-42.4h101.5c.2-116.3-64.6-143.9-64.6-143.9Z" />
        <path
          d="M498.6,550.3c-28.4,0-51.5-23.1-51.5-51.4c0-28.4,23.1-51.4,51.5-51.4s51.4,23.1,51.4,51.4-23,51.4-51.4,51.4Z"
          fill="#fff"
        />
        <path d="M464.3,498.8c0-19,15.4-34.3,34.3-34.3s34.3,15.4,34.3,34.3c0,19-15.4,34.3-34.3,34.3s-34.3-15.3-34.3-34.3v0Z" />
      </g>
    </svg>
  );
};

export default PumpSVGOff;

import { PlayCircle, StopCircle } from "react-feather";
import { useMqttState } from "../@mqtt-react-hooks";
import PumpSVGOff from "./PumpSVGOff";
import WidgetStatCard from "./shared/WidgetStatusCard";

interface Props {
  attribute: string;
  value: boolean;
  deviceId: string;
  pump: "water" | "acid" | "viberex";

  bgClassName: string;
  btnClassName: string;
  btnTextClassName: string;
}

const PumpCard = ({ attribute, value, deviceId, pump, bgClassName, btnClassName, btnTextClassName }: Props) => {
  const { client } = useMqttState();

  const pumpAction = (action: "start" | "stop") =>
    client?.publish(`CONTROL/${deviceId}`, JSON.stringify({ action, pump }));

  return (
    <>
      {/* <div className={`text-xl flex w-full  `}>
        <div className="w-1/2 p-2">{attribute}:</div>
        <div className="w-1/2 p-2 uppercase flex justify-between">
          {value.toString()}
          {value ? (
            <StopCircle className="cursor-pointer" onClick={() => pumpAction("stop")} />
          ) : (
            <PlayCircle className="cursor-pointer" onClick={() => pumpAction("start")} />
          )}
        </div>
      </div> */}
      <WidgetStatCard
        title={attribute}
        value={value ? "running" : "stopped"}
        subtitle={
          <div className="flex justify-end text-gray-800 ">
            {/* <div className="mx-3 mb-0 font-semibold text-md text-lg text-white">{value ? "running" : "stopped"}</div> */}
            <div className="">
              {value ? (
                <StopCircle size={32} className="cursor-pointer" onClick={() => pumpAction("stop")} />
              ) : (
                <PlayCircle size={32} className="cursor-pointer" onClick={() => pumpAction("start")} />
              )}
            </div>
          </div>
        }
        icon={<PumpSVGOff />}
        fill={true}
        reverse={true}
        bgClassName={bgClassName} //`{bg-${color}-500`
        btnClassName={btnClassName} //{`bg-${color}-100`}
        btnTextClassName={btnTextClassName} //{`bg-${color}-600`}
      />
    </>
  );
};

export default PumpCard;

import classNames from "classnames";

interface ICardProps {
  children: React.ReactNode;
  className?: string;
}

export const Card = ({ children, className, ...props }: ICardProps) => (
  <div
    {...props}
    className={classNames(
      `relative min-w-0 break-words rounded-lg overflow-hidden shadow-sm mb-4 w-full bg-white`,
      className
    )}
  >
    {children}
  </div>
);

export const CardBody = ({ children, className, ...props }: ICardProps) => (
  <div {...props} className={classNames(`px-6 py-6 relative`, className)}>
    {children}
  </div>
);

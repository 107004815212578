import { Edge, Node, Position } from "react-flow-renderer";

export const initialNodes: Node[] = [
  {
    id: "wt",
    type: "Tank",
    data: { label: "Water Tank", status: "normal", type: "water" },
    position: { x: -190, y: 250 },
    sourcePosition: Position.Top,
  },
  {
    id: "wp",
    type: "Pump",
    data: { label: "Water Pump", type: "water", status: false },
    position: { x: -60, y: 80 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: "pt",
    type: "Tank",
    data: { label: "Production Tank", status: "normal", type: "production" },
    position: { x: 20, y: 250 },
  },

  {
    id: "at",
    type: "Tank",
    data: { label: "Acid Tank", status: "empty", type: "acid" },
    position: { x: 378, y: 60 },
    sourcePosition: Position.Top,
  },
  {
    id: "ap",
    type: "Pump",
    data: { label: "Acid Pump", type: "acid", status: false },
    position: { x: 220, y: 0 },
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },

  {
    id: "vt",
    type: "Tank",
    data: { label: "Viberex Tank", status: "empty", type: "viberex" },
    position: { x: 378, y: 250 },
    sourcePosition: Position.Top,
  },
  {
    id: "vp",
    type: "Pump",
    data: { label: "Viberex Pump", type: "viberex", status: false },
    position: { x: 220, y: 190 },
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
];
const edgeType = "smoothstep";
export const initialEdges: Edge[] = [
  { id: "wt-wp", source: "wt", target: "wp", animated: false, className: "water-edge", type: edgeType },
  { id: "wt-wp-flow", source: "wt", target: "wp", animated: true, className: "flow-path", type: edgeType },
  { id: "wp-pt", source: "wp", target: "pt", animated: false, className: "water-edge", type: edgeType },
  {
    id: "wp-pt-flow",
    source: "wp",
    target: "pt",
    animated: true,
    className: "flow-path",
    type: edgeType,
    targetHandle: "wp-pt",
  },

  { id: "at-ap", source: "at", target: "ap", animated: false, className: "acid-edge", type: edgeType },
  { id: "at-ap-flow", source: "at", target: "ap", animated: true, className: "flow-path", type: edgeType },
  { id: "ap-at", source: "ap", target: "pt", animated: false, className: "acid-edge", type: edgeType },
  { id: "ap-at-flow", source: "ap", target: "pt", animated: true, className: "flow-path", type: edgeType },

  { id: "vt-vp", source: "vt", target: "vp", animated: false, className: "viberex-edge", type: edgeType },
  { id: "vt-vp-flow", source: "vt", target: "vp", animated: true, className: "flow-path", type: edgeType },
  { id: "vp-pt", source: "vp", target: "pt", animated: false, className: "viberex-edge", type: edgeType },
  { id: "vp-pt-flow", source: "vp", target: "pt", animated: true, className: "flow-path", type: edgeType },
];

export interface IProductionData {
  device_id: string;
  production_status: "started" | "stopped";
  acid_tank: "low" | "empty" | "full";
  water_tank: "low" | "empty" | "full";
  product_tank: "low" | "empty" | "full";
  viberex_tank: "low" | "empty" | "full";
  production_start_status: "low" | "high";
  production_end_status: "low" | "high";
  water_bund: "high_alarm" | "normal";
  prod_bund: "high_alarm" | "normal";
  stopped_reason: "overflow" | "user stopped" | "tank empty" | "";
  alert: "warning" | "stop" | "no_alerts" | "";
  alert_text?: string;
  acid_pump: boolean;
  viberex_pump: boolean;
  water_pump: boolean;
  acid_flow: number;
  water_flow: number;
  viberex_flow: number;
}

export const defaultProductionData: IProductionData = {
  device_id: "xx-xx-xx-xx-xx",
  production_status: "stopped",
  acid_tank: "empty",
  water_tank: "empty",
  product_tank: "empty",
  viberex_tank: "empty",
  production_start_status: "low",
  production_end_status: "low",
  water_bund: "normal",
  prod_bund: "normal",
  stopped_reason: "tank empty",
  alert: "no_alerts",
  alert_text: "",
  acid_pump: false,
  viberex_pump: false,
  water_pump: false,
  acid_flow: 0,
  water_flow: 0,
  viberex_flow: 0,
};

export interface ITankData {
  type: "wt" | "at" | "vt" | "pt";
  status: "low" | "empty" | "full" | "high_alarm";
}

export interface IPumpData {
  type: "wp" | "ap" | "vp";
  status: boolean;
}

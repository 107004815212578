import { useState } from "react";
import Slider from "react-rangeslider";
import StatusAttribute from "./StatusAttribute";
import { IProductionData } from "./../flowData";
import { useMqttState } from "../@mqtt-react-hooks/";
import PumpCard from "./PumpCard";
import TankCard from "./TankCard";
import Logo from "../@assets/process-water.jpg";
import "react-rangeslider/lib/index.css";

interface IProps {
  productionData: IProductionData;
  setProductionData: React.Dispatch<React.SetStateAction<IProductionData>>;
  startStop: (start_stop: "start" | "stop") => void;
  deviceId: string;
}

enum MQTT_TOPICS {
  "WATER_PUMP_CONTROL" = "WATER_PUMP_CONTROL",
}

const ProductionStatus = ({ productionData, setProductionData, startStop, deviceId }: IProps) => {
  const [waterPumpOpening, setWaterPumpOpening] = useState(100);
  const { client } = useMqttState();

  const toggleProduction = (toggleType: string) => {
    if (toggleType === "start") {
      if (productionData.production_status === "started") return;
      setProductionData({
        ...productionData,
        production_status: "started",
        water_pump: true,
        acid_pump: true,
        viberex_pump: true,
      });
    }

    if (toggleType === "stop") {
      if (productionData.production_status === "stopped") return;
      setProductionData({
        ...productionData,
        production_status: "stopped",
        water_pump: false,
        acid_pump: false,
        viberex_pump: false,
      });
    }
  };

  const pumpAction = (action: "start" | "stop", pump: string) =>
    client?.publish(`CONTROL/${deviceId}`, JSON.stringify({ action, pump }));

  const onWaterPumpOpeningChange = (v: number) => setWaterPumpOpening(parseInt(v.toString()));
  const onWaterPumpOpeningChangeCompleted = () => {
    client?.publish(`${MQTT_TOPICS.WATER_PUMP_CONTROL}/${deviceId}`, JSON.stringify({ value: waterPumpOpening }));
  };

  const getTextClassName = (status: string) => {
    if (status === "high_alarm" || status === "empty") return "text-red-600";
    if (status === "low") return "text-yellow-600";
    return "text-green-600";
  };

  console.log(productionData?.alert_text);

  return (
    <div className="w-2/3 h-auto m-auto min-h-screen">
      <div className="flex flex-row items-center justify-start w-full h-full">
        <img src={Logo} alt="logo" className="w-24 mr-9" />
        <h2 className="text-3xl">Process Pumps - Production Status</h2>
      </div>
      <hr className="bg-white w-[calc(100%-10px)] my-2 h-1" />
      <div className="flex w-full">
        <div className="flex w-1/2 justify-evenly flex-col px-5">
          <div className="w-full border-white border p-2 rounded-md">
            Water Pump Opening
            <Slider
              className="w-full h-2 bg-blue-400 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              min={0}
              max={100}
              value={waterPumpOpening}
              onChange={onWaterPumpOpeningChange}
              labels={{ 0: "0% (0 lph)", 100: "100% (65 lph)" }}
              onChangeComplete={() => onWaterPumpOpeningChangeCompleted()}
            />
          </div>
          <div className="flex w-full justify-evenly p-2">
            <button
              className="button start"
              onClick={() => startStop("start")}
              disabled={productionData.production_status === "started"}
            >
              START
            </button>
            <button
              className="button stop"
              onClick={() => startStop("stop")}
              disabled={productionData.production_status === "stopped"}
            >
              STOP
            </button>
          </div>
        </div>
        <div className="flex w-1/2 justify-around p-2 flex-col px-10">
          <StatusAttribute className="text-2xl" attribute="Status" value={productionData.production_status} />
          <StatusAttribute
            className="text-2xl"
            attribute="Start Status"
            value={productionData.production_start_status}
          />
          <StatusAttribute className="text-2xl" attribute="Stopped Reason" value={productionData.stopped_reason} />
          <StatusAttribute
            className="text-2xl"
            attribute="Prod End Status"
            value={productionData.production_end_status}
          />
        </div>
      </div>
      <hr className="bg-white w-[calc(100%-10px)] my-2 h-1" />

      <div className="flex w-full flex-col">
        <div className="flex w-full justify-evenly flex-row px-5">
          <TankCard
            attribute="Water Tank"
            value={productionData.water_tank.replace("_", " ")}
            deviceId={deviceId}
            cardClassName="border-blue-500"
            textClassName="text-blue-500"
          />
          <TankCard
            attribute="Acid Tank"
            value={productionData.acid_tank}
            deviceId={deviceId}
            cardClassName="border-yellow-500"
            textClassName="text-yellow-500"
          />
          <TankCard
            attribute="Viberex Tank"
            value={productionData.viberex_tank}
            deviceId={deviceId}
            cardClassName="border-green-500"
            textClassName="text-green-500"
          />
          <TankCard
            attribute="Production Tank"
            value={productionData.product_tank.replace("_", " ")} 
            deviceId={deviceId}
            cardClassName="border-indigo-500"
            textClassName="text-indigo-500"
          />
        </div>
        <hr className="bg-white w-[calc(100%-10px)] my-2 h-1" />
        <div className="flex w-full justify-evenly flex-row px-5">
          <PumpCard
            pump="water"
            attribute="Water Pump"
            value={productionData.water_pump}
            deviceId={deviceId}
            bgClassName="bg-blue-500"
            btnClassName="text-blue-100"
            btnTextClassName="text-blue-600"
          />
          <PumpCard
            pump="acid"
            attribute="Acid Pump"
            value={productionData.acid_pump}
            deviceId={deviceId}
            bgClassName="bg-yellow-500"
            btnClassName="text-yellow-100"
            btnTextClassName="text-yellow-600"
          />
          <PumpCard
            pump="viberex"
            attribute="Viberex Pump"
            value={productionData.viberex_pump}
            deviceId={deviceId}
            bgClassName="bg-green-500"
            btnClassName="text-green-100"
            btnTextClassName="text-green-600"
          />
        </div>
      </div>

      <hr className="bg-white w-[calc(100%-10px)] my-2 h-1" />
      <div className="flex w-full">
        <div className="flex w-1/2 justify-evenly flex-col px-10">
          <StatusAttribute attribute="ALERTS" value={productionData.alert.toString().replace("_", " ")} />
        </div>
        <div className="flex w-1/2 justify-evenly flex-col px-10"></div>
      </div>
      <div className="flex w-full">
        <div className="flex w-full justify-evenly flex-col px-10">
          {productionData?.alert !== "" && productionData?.alert !== "no_alerts" && (
            <div
              className={`w-full p-2 text-2xl uppercase alert ${
                productionData.alert.toString() === "STOP" ? "alert-danger" : "alert-warning"
              }`}
            >
              {productionData?.alert_text}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full p-10 flex-col"></div>
    </div>
  );
};

export default ProductionStatus;
